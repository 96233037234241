<template>
  <div>
    <v-card v-if="showAccount" class="mb-4">
      <div v-if="isSeller" class="seller">
        <div
          v-if="account && account.status === accountStatus.DEMO"
          class="pa-1 pb-0"
        >
          <v-alert
            dense
            text
            color="#6F8FAE"
            class="text-center caption pa-1 ma-0">
            This merchant is for demonstration purposes only
          </v-alert>
        </div>
        <v-card-title class="title-container align-start">
          <div class="title-data collapsed mb-2" ref="title-data">
            <h1 class="title mb-2"><router-link :to="{name: 'profile', params: {account: account.account}}" class="black--text">{{ account.account }}</router-link></h1>
            <router-link v-if="isActiveUserAccount" :to="{name: 'account-image', params: {account: account.account}}">
              <v-avatar :color="accountMedia ? null : '#7070a0'" size="100" class="mr-4 mb-2">
                <v-img v-if="accountMedia" :src="accountMedia" :alt="account.account" />
                <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
              </v-avatar>
            </router-link>
            <template v-else>
              <v-avatar :color="accountMedia ? null : '#7070a0'" size="100" class="mr-4 mb-2">
                <v-img v-if="accountMedia" :src="accountMedia" :alt="account.account" />
                <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
              </v-avatar>
            </template>
            <div class="body-2 my-0" @click="toggleHeight">
              <nl2br v-if="account.about" tag="p" :text="account.about"></nl2br>
            </div>
          </div>
          <div class="read-more-btn" v-if="showButton">
            <v-btn x-small text type="button" @click="toggleHeight">Read more&hellip;</v-btn>
          </div>
        </v-card-title>

        <v-card-text class="py-0 d-flex counts">
          <v-btn
            :to="{name: 'followers', params: {account: account.account}}"
            class="count caption"
            text
            tile
            exact
          >
            <div class="title">{{ followerCount }}</div> followers
          </v-btn>
          <v-btn
            :to="{name: 'following', params: {account: account.account}}"
            class="count caption"
            text
            tile
            exact
          >
            <div class="title">{{ followedCount }}</div> following
          </v-btn>
          <v-btn
            @click.prevent="mutableProductCount ? $router.push({name: 'account-products', params: {account: account.account}}, () => {}) : null"
            class="count caption"
            text
            tile
            exact
          >
            <div class="title">{{ mutableProductCount }}</div> products
          </v-btn>
          <v-btn
            @click.prevent="postCount ? $router.push({name: 'profile', params: {account: account.account}}, () => {}) : null"
            class="count caption"
            text
            tile
            exact
          >
            <div class="title">{{ postCount }}</div> posts
          </v-btn>
        </v-card-text>
        <v-card-actions class="align-center justify-center buttons">
          <v-btn
            v-if="isActiveUserAccount"
            :to="{name: 'account-products-manage', params: {account: account.account}}"
            type="button"
            color="#7070a0"
            class="mx-1"
            depressed
            small
            dark
          >
            <v-icon left dark>{{ icon.mdiShopping }}</v-icon>
            Your store
          </v-btn>
          <v-btn
            v-else
            :to="{name: 'account-products', params: {account: account.account}}"
            type="button"
            color="#7070a0"
            class="mx-1"
            depressed
            small
            dark
          >
            <v-icon left dark>{{ icon.mdiShopping }}</v-icon>
            Shop
          </v-btn>
          <v-btn
            v-if="!isActiveUserAccount && hasAccountFollow"
            :to="{name: 'messages-convo', params: {account: user.account, recipient: account.account}}"
            type="button"
            color="#7070a0"
            class="mx-1"
            depressed
            small
            dark
          >
            <v-icon left dark>{{ icon.mdiMessageText }}</v-icon>
            Message
          </v-btn>
          <template v-if="user">
            <v-btn
              v-if="hasAccountFollow"
              type="button"
              color="#7070a0"
              class="mx-1"
              depressed
              small
              dark
              @click="unfollow"
            >
              Following
            </v-btn>
            <v-btn
              v-else-if="!isActiveUserAccount"
              type="button"
              color="#7070a0"
              class="mx-1"
              depressed
              small
              dark
              @click="follow"
            >
              <v-icon left dark>{{ icon.mdiAccountPlus }}</v-icon>
              Follow
            </v-btn>
          </template>
        </v-card-actions>
        <v-card-actions
          v-if="tags && tags.length"
          class="d-flex flex-wrap justify-center">
          <v-chip
            v-for="tag in tags"
            :key="tag"
            :dark="isActiveFilter(tag)"
            :close="isActiveFilter(tag)"
            :color="isActiveFilter(tag) ? '#7070a0' : null"
            class="ma-1"
            small
            @click="toggleFilter(tag)"
            @click:close="toggleFilter(tag)"
          >
            {{ tag }}
          </v-chip>
        </v-card-actions>
      </div>

      <div v-else class="buyer">
        <v-card-title>
          <v-list-item>
            <router-link v-if="isActiveUserAccount" :to="{name: 'account-image', params: {account: account.account}}">
              <v-list-item-avatar :color="accountMedia ? null : '#7070a0'" size="100">
                <v-img v-if="accountMedia" :src="accountMedia" :alt="account.account" />
                <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
              </v-list-item-avatar>
            </router-link>
            <template v-else>
              <v-list-item-avatar :color="accountMedia ? null : '#7070a0'" size="100">
                <v-img v-if="accountMedia" :src="accountMedia" :alt="account.account" />
                <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
              </v-list-item-avatar>
            </template>

            <v-list-item-content>
              <v-list-item-title><h1 class="title">{{ account.account }}</h1></v-list-item-title>
              <v-list-item-subtitle>{{ account.legalName }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="counts">
              <v-btn
                :to="{name: 'following', params: {account: account.account}}"
                class="count caption"
                text
                tile
                exact
              >
                <div class="title">{{ followedCount }}</div> following
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
      </div>
    </v-card>

    <StreamPost
      v-if="$route.name === 'profile' && isSeller && pinnedPost"
      is-pinned-post
      :item="pinnedPost"
      :key="pinnedPost.id"
      class="mb-4"
    />
  </div>
</template>

<script>
  import StreamPost from '@/components/StreamPost'
  import {
    mdiShopping,
    mdiMessageText,
    mdiAccountPlus
  } from '@mdi/js'
  import { mapGetters } from 'vuex'
  import Nl2br from 'vue-nl2br'
  import { getImageURL } from '@/image-cdn'
  import { accountStatus } from '@/enums'

  const ALLOWED_HEIGHT_VARIANCE = 8

  export default {

    name: 'AccountHeader',

    components: {
      Nl2br,
      StreamPost
    },

    props: [
      'account',
      'tags',
      'productCount'
    ],

    data() {
      return {
        accountStatus,
        icon: {
          mdiShopping,
          mdiMessageText,
          mdiAccountPlus
        },
        showButton: false,
        showAccount: false,
        followedCount: 0,
        followerCount: 0,
        postCount: 0,
        mutableProductCount: 0,
        pinnedPost: null
      }
    },

    computed: {
      filter() { return this.$route.query.filter },
      isSeller() { return this.account && this.account.type === 'seller' },
      isActiveUserAccount() { return Boolean(this.user && this.user.account == this.account.account) },
      accountMedia() { return this.account && this.account.media ? getImageURL(this.account.media, 100, 100, this.account.updated || this.account.created) : null },
      accountFollow() { return this.user ? {follower: this.user.account, followType: 'account', followID: this.account.account} : false },
      hasAccountFollow() { return this.accountFollow ? Boolean(this.getFollow(this.accountFollow)) : false },
      ...mapGetters([
        'getFollow'
      ])
    },

    watch: {
      productCount(value) {
        if (value) {
          this.mutableProductCount = value
        }
      }
    },

    mounted() {
      const loaders = [
        this.$store.dispatch('getFollowedCount', this.account.account).then(count => this.followedCount = count)
      ]

      if (this.isSeller) {
        loaders.concat([
          this.$store.dispatch('getPostCountForAccount', this.account.account).then(count => this.postCount = count),
          this.$store.dispatch('getProductCountForAccount', this.account.account).then(count => this.mutableProductCount = count),
          this.$store.dispatch('getFollowerCount', this.account.account).then(count => this.followerCount = count)
        ])

        if (this.account.pinnedPost) {
          loaders.push(this.$store.dispatch('getPost', this.account.pinnedPost).then(post => this.pinnedPost = post && {id: post.id, ...post.data()}))
        }
      }

      Promise.all(loaders)
        .then(() => this.showAccount = true)
        .catch(error =>
          this.logError('Problem loading data.', { error: error.message })
        )
    },

    updated() {
      if ('title-data' in this.$refs) {
        const el = this.$refs['title-data']
        const heightDiff = el.scrollHeight && el.offsetHeight ? Boolean(el.scrollHeight - el.offsetHeight > ALLOWED_HEIGHT_VARIANCE) : false
        if (heightDiff) {
          this.showButton = heightDiff
          el.className += ' read-more'
        }
      }
    },

    methods: {
      follow() {
        this.$store.dispatch('follow', this.accountFollow)
          .then(() => this.followerCount += 1)
      },
      unfollow() {
        this.$store.dispatch('unfollow', this.accountFollow)
          .then(() => this.followerCount -= 1)
      },
      isActiveFilter(tag) {
        return this.filter && this.filter.split(',').includes(tag)
      },
      toggleFilter(tag) {
        const baseRoute = {
          name: this.$route.name,
          params: this.$route.params
        }

        const filters = this.filter ? this.filter.split(',') : []

        if (filters.includes(tag)) {
          filters.pop(tag)
        } else {
          filters.push(tag)
        }

        this.$router.push(
          filters.length ?
            {...baseRoute, query: {filter: filters.join(',')}} :
            baseRoute,
          () => {}
        )
      },
      toggleHeight() {
        if (this.$refs && 'title-data' in this.$refs) {
          this.$refs['title-data'].classList.toggle('collapsed');
        }
      }
    }
  }
</script>

<style lang="scss">
  .title-container {
    min-height: 116px;
    padding-bottom: 0;
    position: relative;

    .read-more-btn {
      flex: 1 1 100%;
      text-align: right;
      padding-right: 8px;
    }
  }
  .title-data {
    overflow-wrap: normal;
    word-break: normal;

    transition-property: max-height;
    transition-delay: 0;
    transition-duration: 0.2s;

    width: 100%;
    height: auto;
    max-height: 600px;

    a {
      text-decoration: none;
      color: inherit;
    }

    .v-avatar {
      float: left;
    }

    .caption {
      cursor: pointer;

      &>p:last-child {
        margin-bottom: 0;
      }
    }

    &.collapsed {
      max-height: 140px;
      overflow-y: hidden;
    }

    &.read-more {
      margin-bottom: 1.2em;
    }
  }

  .counts .v-btn.v-size--default.count {
    height: auto;
    flex: 1 1 25%;

    padding: 0;

    &>.v-btn__content {
      flex-wrap: wrap;
      padding: 4px 8px 8px;
      font-size: 10px;

      .title {
        flex: 0 0 100%;
        text-align: center;
        font-size: 20px;
      }
    }

    .buttons {
      .v-btn.v-size--small {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .buyer .v-list-item {
    padding: 0;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__avatar,
    .v-list-item__action {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
</style>
