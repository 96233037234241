<template>
  <v-app-bar
    app
    dense
    fixed
    flat
    class="app-bar elevation-0"
  >
    <v-toolbar-title>
      <router-link :to="{ name: 'stream' }" exact>
        <Logo viewBox="0 0 226 54" height="30" class="mt-2" />
      </router-link>
    </v-toolbar-title>

    <div class="flex-grow-1"></div>

    <v-toolbar-items>
      <div v-if="user && user.account">
        <v-btn icon tile :to="{name: 'wishlist'}">
          <v-icon v-if="!listQuantity">{{ icon.mdiClipboardList }}</v-icon>
          <v-badge v-else-if="listQuantity < 100" overlap color="#7070a0" :content="listQuantity">
            <v-icon>{{ icon.mdiClipboardList }}</v-icon>
          </v-badge>
          <v-badge v-else overlap color="#7070a0" content="99+">
            <v-icon>{{ icon.mdiClipboardList }}</v-icon>
          </v-badge>
        </v-btn>
        <v-btn icon tile :to="{name: 'cart'}">
          <v-icon v-if="!cartQuantity">{{ icon.mdiCart }}</v-icon>
          <v-badge v-else-if="cartQuantity < 100" overlap color="#7070a0" :content="cartQuantity">
            <v-icon>{{ icon.mdiCart }}</v-icon>
          </v-badge>
          <v-badge v-else overlap color="#7070a0" content="99+">
            <v-icon>{{ icon.mdiCart }}</v-icon>
          </v-badge>
        </v-btn>
      </div>
      <v-btn icon tile @click.stop="navigation = !navigation">
        <v-icon>{{ icon.mdiMenu }}</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Logo from '@/assets/nuokka.svg'
  import {
    mdiClipboardList,
    mdiCart,
    mdiMenu
  } from '@mdi/js'

  export default {

    name: 'AppHeader',

    components: {
      Logo
    },

    data: () => ({
      icon: {
        mdiClipboardList,
        mdiCart,
        mdiMenu
      }
    }),

    computed: {
      ...mapGetters([
        'cart',
        'list'
      ]),
      cartQuantity() { return this.calculateQuantity(this.cart) },
      listQuantity() { return this.calculateQuantity(this.list) },
      navigation: {
        get: function() {
          return this.$store.getters.navigation
        },
        set: function(newValue) {
          this.$store.dispatch('setNav', newValue)
        }
      }
    },

    methods: {
      calculateQuantity(list) {
        const items = (list && list.items) || []
        return items.reduce((sum, item) => {
          if (item) {
            return sum + Number(item.options.quantity)
          }
        }, 0)
      }
    }
  }
</script>

<style lang="scss" scoped>
  header.v-sheet.app-bar {
    z-index: 11;
  }
</style>
