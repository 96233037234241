<template>
  <div v-if="src">
    <video
      ref="videoPlayer"
      preload="auto"
      controls
      controlsList="nodownload"
      playsinline
      disablePictureInPicture
      class="video-js vjs-theme-forest vjs-fluid"
      v-bind="$attrs"
      v-on="inputListeners"
      @contextmenu.prevent="() => false"
    >
      <source :src="src" type="video/mp4" />
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank"
          >supports HTML5 video</a
        >
      </p>
    </video>
  </div>
</template>

<script>
  import videojs from 'video.js'

  export default {

    name: 'VideoPlayer',

    inheritAttrs: false,

    props: {
      src: String,
      options: {
        type: Object,
        default: () => ({})
      }
    },

    data: () => ({
      player: null
    }),

    computed: {
      inputListeners: function () {
        return Object.assign({},
          this.$listeners
        )
      }
    },

    mounted() {
      this.player = videojs(this.$refs.videoPlayer, this.options)
      this.player.load()
    },

    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    }
  }
</script>

<style lang="scss" scoped>
  video {
    background-color: #fff;
  }
</style>
