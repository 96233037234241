<template>
  <div>
    <v-text-field
      label="Description"
      hint="A label to identify the address in your address book. e.g. “Home”,
      “Head Office”, or “Fred’s House”"
      persistent-hint
      required
      v-model.trim="$v.address.description.$model"
      @input="touchEmit($event, $v.address.description)"
      @blur="touchEmit($event, $v.address.description)"
      :error-messages="requiredError($v.address.description, 'Description')"
    ></v-text-field>
    <v-text-field
      label="Address Contact"
      required
      v-model.trim="$v.address.contact.$model"
      @input="touchEmit($event, $v.address.contact)"
      @blur="touchEmit($event, $v.address.contact)"
      :error-messages="requiredError($v.address.contact, 'Address contact')"
    ></v-text-field>
    <v-text-field
      label="Address Line 1"
      required
      v-model.trim="$v.address.line1.$model"
      @input="touchEmit($event, $v.address.line1)"
      @blur="touchEmit($event, $v.address.line1)"
      :error-messages="requiredError($v.address.line1, 'Address line 1')"
    ></v-text-field>
    <v-text-field
      label="Address Line 2 (optional)"
      v-model.trim="address.line2"
      @input="$emit('input', address)"
    ></v-text-field>
    <v-text-field
      label="City / Suburb (optional)"
      hint="City, district, suburb, town, or village."
      persistent-hint
      v-model.trim="address.city"
      @input="$emit('input', address)"
    ></v-text-field>
    <v-text-field
      label="State / County (optional)"
      hint="State, county, province, or region."
      persistent-hint
      v-model.trim="address.state"
    ></v-text-field>
    <v-text-field
      label="Postal code (optional)"
      v-model.trim="address.postalCode"
      @input="$emit('input', address)"
    ></v-text-field>
    <TerritorySelect
      label="Country"
      class="my-2"
      required
      v-model.trim="$v.address.country.$model"
      @input="touchEmit($event, $v.address.country)"
      @blur="touchEmit($event, $v.address.country)"
      :error-messages="requiredError($v.address.country, 'Country')"
    ></TerritorySelect>
    <v-checkbox
      v-if="!mutableDefault"
      v-model="address.isDefault"
      @input="$emit('input', address)"
      color="#7070a0"
      label="Set as default address"
      hide-details
    ></v-checkbox>
    <v-checkbox
      v-model="address.collectionAvailable"
      @input="$emit('input', address)"
      color="#7070a0"
      label="Collection available from this address"
      hide-details
    ></v-checkbox>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import TerritorySelect from '@/components/TerritorySelect'

  export default {

    name: 'AddressEdit',

    mixins: [validationMixin],

    components: {
      TerritorySelect
    },

    props: {
      value: Object,
      default: Boolean
    },

    data() {
      return {
        address: {
          ulid: '',
          description: '',
          isDefault: this.default || false,
          collectionAvailable: false,
          contact: '',
          line1: '',
          line2: '',
          city: '', // City, district, suburb, town, or village.
          state: '', // State, county, province, or region.
          postalCode: '',
          country: null
        },
        mutableDefault: this.default,
      }
    },

    validations: {
      address: {
        description: { required },
        contact: { required },
        line1: { required },
        country: { required }
      }
    },

    computed: {
      $invalid() {
        return this.$v.$invalid
      }
    },

    watch: {
      value(val) {
        if (val) {
          this.address = val
        }
      }
    },

    methods: {
      requiredError(field, label) {
        return field.$dirty && !field.required ? label + ' is required' : ''
      },
      touchEmit(event, field) {
        field.$touch()
        this.$emit('input', this.address)
      },
      $touch(...arg) {
        return this.$v.$touch(...arg)
      },
      $reset(...arg) {
        return this.$v.$reset(...arg)
      }
    }
  }
</script>
