<template>
  <div>
    <v-toolbar
      class="post-header"
      dense="dense"
      flat
    >
      <v-avatar
        :color="accountMedia ? null : '#7070a0'"
        size="24"
        class="mr-3"
        @click.stop="$router.push({ name: 'profile', params: { account: account }}, () => {})">
        <v-img v-if="accountMedia" :src="accountMedia" :alt="account" />
        <span v-else class="white--text headline">{{ account[0].toUpperCase() }}</span>
      </v-avatar>
      <v-toolbar-title class="body-2">
        <router-link :to="{ name: 'profile', params: { account: account }}">{{ account }}</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-toolbar>
    <div
      v-if="accountObj && accountObj.status === accountStatus.DEMO"
      class="pa-1 pt-0"
    >
      <v-alert
        dense
        text
        color="#6F8FAE"
        class="text-center caption pa-1 ma-0">
        This merchant is for demonstration purposes only
      </v-alert>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getImageURL } from '@/image-cdn'
  import { accountStatus } from '@/enums'

  export default {

    name: 'AccountHeaderSmall',

    props: [
      'account',
      'dense'
    ],

    data: () => ({
      accountMedia: false,
      accountAddress: false,
      accountStatus,
      accountObj: null
    }),

    mounted() {
      this.populateAccountMedia()
    },

    beforeDestroy() {
      delete this.accountMedia
      delete this.accountObj
    },

    methods: {
      ...mapActions(['getAccount']),

      populateAccountMedia() {
        return this.account && this.getAccount(this.account)
          .then(account => {
            this.accountObj = account
            this.accountMedia = account.media &&
              getImageURL(account.media, 80, 80, account.updated || account.created)
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .post-header {
    .v-avatar {
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
</style>
