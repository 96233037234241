<template>
  <div>
    <v-bottom-navigation
      v-if="!this.appLoading"
      class="elevation-0"
      app
      fixed
      grow
    >
      <v-btn
        :to="{name: 'stream'}"
        icon
        small
        exact
      >
        <v-icon>{{ icon.mdiHome }}</v-icon>
      </v-btn>

      <v-btn
        @click="$router.go(-1)"
        icon
        small
        exact
      >
        <v-icon>{{ icon.mdiArrowLeft }}</v-icon>
      </v-btn>

      <template v-if="user && user.account && !this.appLoading">
        <v-menu
          v-if="isSeller"
          z-index="1"
          top
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              large
            >
              <v-icon>{{ icon.mdiPlusCircle }}</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              :to="{name: 'new-post'}"
              exact
            >
              <v-list-item-title>
                <v-icon left>{{ icon.mdiPlusCircle }}</v-icon>
                Add new post
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'add-product', params: {account: user.account} }"
              exact
            >
              <v-list-item-title>
                <v-icon left>{{ icon.mdiPlusCircle }}</v-icon>
                Add new product
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          :to="{name: 'messages', params: {account: user.account}}"
          icon
          small
          exact
        >
          <v-icon v-if="!unRead">{{ icon.mdiMessageText }}</v-icon>
          <v-badge v-else-if="unRead < 100" overlap color="#7070a0" :content="unRead">
            <v-icon>{{ icon.mdiMessageText }}</v-icon>
          </v-badge>
          <v-badge v-else overlap color="#7070a0" content="99+">
            <v-icon>{{ icon.mdiMessageText }}</v-icon>
          </v-badge>
        </v-btn>

        <v-btn
          :to="{name: 'profile', params: {account: user.account}}"
          icon
          small
          exact
        >
          <v-icon>{{ icon.mdiAccountBox }}</v-icon>
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          :to="{name: 'signup'}"
        >
          Free sign-up
          <v-icon>{{ icon.mdiAccount }}</v-icon>
        </v-btn>
        <v-btn
          @click="$store.dispatch('setShowLogin', true)"
        >
          Login
          <v-icon>{{ icon.mdiLogin }}</v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    mdiHome,
    mdiArrowLeft,
    mdiPlusCircle,
    mdiMessageText,
    mdiAccountBox,
    mdiAccount,
    mdiLogin
  } from '@mdi/js'
  import icon from '@/../public/img/icons/icon-72x72.png'

  export default {

    name: 'NavigationBottom',

    data: () => ({
      icon: {
        mdiHome,
        mdiArrowLeft,
        mdiPlusCircle,
        mdiMessageText,
        mdiAccountBox,
        mdiAccount,
        mdiLogin
      }
    }),

    watch: {
      unreadNotices(unreadMessages){
        if (unreadMessages.length > 0) {
          const lastMessage = unreadMessages[unreadMessages.length - 1]
          this.notification(`Unread notice from ${lastMessage.sender}`, lastMessage.text)
        }
      },
      unReadConversations(unreadConversations){
        if(unreadConversations.length > 0){
          let lastConversationMessages = unreadConversations[unreadConversations.length - 1].messages
          let lastMessageSent = lastConversationMessages[lastConversationMessages.length - 1]
          this.notification(`Unread message from ${lastMessageSent.sender}`, lastMessageSent.text)
        }
      }
    },

    computed: {
      ...mapGetters([
        'conversations',
        'appLoading',
        'isSeller',
        'messages',
      ]),
      hasMessageMeta() { return Boolean(this.$route.query.sharePost || this.$route.query.shareProduct) },
      unreadNotices() {
           return this.messages.filter(m => !m.read)
      },
      unReadConversations(){
        return this.conversations
          .filter(conv =>
            this.user &&
            this.user.account in conv.participantState &&
            !conv.participantState[this.user.account].read
          )
      },
      unRead() {
        return this.unReadConversations.length +
          this.unreadNotices.length
      }
    },

    methods: {
      notification (title, body) {
        if (window.Notification || window.webkitNotification) {
          this.$notification.show(title, {
            body,
            icon
          }, {})
        }
      }
    }
  }
</script>
