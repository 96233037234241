<template>
  <div @input="$emit('input', mutableValue)">
    <h2 class="headline mb-2">{{ title }}</h2>
    <div class="d-flex align-start" v-if="!isSingleCurrency">
      <v-icon color="#7070a0" left>{{ icon.mdiHelpCircle }}</v-icon>
      <p class="caption grey--text text--darken-2"><strong>Note:</strong>
      All prices must include taxes. You may enter different prices for
      different currencies if you are selling in multiple regions.</p>
    </div>
    <v-card-actions v-if="!isSingleCurrency || minimum < 1">
      <div class="flex-grow-1"></div>
      <v-btn
        :disabled="mutableValue.length >= currencyCount"
        icon small @click.stop="addPrice" type="button">
        <v-icon>{{ icon.mdiPlus }}</v-icon>
      </v-btn>
      <v-chip
        class="ma-2"
        color="green"
        text-color="white"
      >{{ mutableValue.length }} price(s)</v-chip>
      <v-btn
        :disabled="mutableValue.length < (minimum + 1)"
        icon small @click.stop="removePrice" type="button">
        <v-icon>{{ icon.mdiMinus }}</v-icon>
      </v-btn>
      <div class="flex-grow-1"></div>
    </v-card-actions>
    <div
      v-for="(v, index) in $v.mutableValue.$each.$iter"
      :key="'price' + index"
    >
      <v-row>
        <v-col class="py-0" cols="6">
          <CurrencySelect
            label="Currency"
            v-model.trim="v.currency.$model"
            @input="v.currency.$touch()"
            @blur="v.currency.$touch()"
            :error-messages="currencyErrors(v)"
          ></CurrencySelect>
        </v-col>
        <v-col class="py-0" cols="6">
          <v-text-field
            label="Unit price"
            class="text-right"
            v-model.trim="v.unitPrice.$model"
            @input="v.unitPrice.$touch()"
            @blur="v.unitPrice.$touch()"
            :error-messages="priceErrors(v)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="v.shipping">
        <v-col class="py-0" cols="6"></v-col>
        <v-col class="py-0" cols="6">
          <v-text-field
            label="Shipping cost"
            class="text-right"
            v-model.trim="v.shipping.$model"
            @input="v.shipping.$touch()"
            @blur="v.shipping.$touch()"
            :error-messages="shippingErrors(v)"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, decimal } from 'vuelidate/lib/validators'
  import CurrencySelect from '@/components/CurrencySelect'
  import {
    mdiPlus,
    mdiMinus,
    mdiHelpCircle
  } from '@mdi/js'

  export default {

    name: 'PriceForm',

    mixins: [validationMixin],

    components: {
      CurrencySelect
    },

    props: {
      value: Array,
      title: String,
      minimum: {
        type: Number,
        default: 1
      },
      includeShipping: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        icon: {
          mdiPlus,
          mdiMinus,
          mdiHelpCircle
        },
        mutableValue: this.value
      }
    },

    computed: {
      ...mapGetters([
        'currencies',
        'account'
      ]),
      currencyCount() {
        return Object.keys(this.currencies).length
      },
      isSingleCurrency() {
        return this.currencyCount === 1
      }
    },

    validations() {
      const each = {
        isUnique: function(currItem, itemArr) {
          var firstIdx = itemArr.findIndex(item =>  currItem.currency === item.currency)
          return Boolean(currItem === itemArr[firstIdx])
        },
        currency: {
          required
        },
        unitPrice: {
          required,
          decimal
        }
      }

      if (this.includeShipping) {
        each.shipping = {
          required,
          decimal
        }
      }

      return {
        mutableValue: {
          $each: each
        }
      }
    },

    methods: {
      priceErrors(v) {
        const errors = []
        if (!v.unitPrice.$dirty) return errors
        !v.unitPrice.required && errors.push('Price is required')
        !v.unitPrice.decimal && errors.push('Price must be a decimal value')
        return errors
      },
      shippingErrors(v) {
        const errors = []
        if (!v.shipping.$dirty) return errors
        !v.shipping.required && errors.push('Shipping is required')
        !v.shipping.decimal && errors.push('Shipping must be a decimal value')
        return errors
      },
      currencyErrors(price) {
        const errors = []
        const field = price.currency
        if (!field.$dirty) return errors
        !field.required && errors.push("Currency is required")
        !price.isUnique && errors.push("Price for this currency already defined")
        return errors
      },
      addPrice() {
        this.mutableValue.push({
          currency: this.account.currency || null,
          unitPrice: null,
          shipping: null
        })
      },
      removePrice() {
        const prices = this.mutableValue
        if(prices.length > this.minimum) prices.pop()
      }
    }
  }
</script>
