<template>
  <v-list dense light>
    <v-list-item :to="postLink">
      <v-list-item-avatar v-if="account">
        <v-img v-if="account.media" :src="account.media" :alt="account.account" />
        <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ post.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ post.account }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getImageURL } from '@/image-cdn'

  export default {

    name: 'SharePost',

    props: {
      post: Object,
      link: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      account: null
    }),

    computed: {
      postLink() {
        return this.link ? {
          name: 'single-post',
          params: {
            account: this.post.account,
            post_id: this.post.id
          }
        } : undefined
      }
    },

    mounted() {
      this.$nextTick(() =>
        this.getAccount(this.post.account)
          .then(account => {
            this.account = account
            this.account.media = this.account && this.account.media ?
              getImageURL(
                this.account.media, 60, 60, this.account.updated ||
                this.account.created
              ) :
              null
          })
      )
    },

    methods: {
      ...mapActions([
        'getAccount'
      ]),
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_colors.scss';

  .v-list {
    border: 1px solid map-get($grey, "lighten-2");
    padding: 0;
    margin: 8px 0;
  }
</style>
