<template>
  <v-sheet
    v-if="componentLoaded"
    class="entry"
    tile
  >
    <div v-if="showMeta" class="entry--meta">
      <SharePost v-if="sharePost" :post="sharePost" />
      <ShareProduct v-if="shareProduct" :product="shareProduct" />
    </div>
    <div class="entry--text">
      <v-textarea
        v-model="message"
        label="Start a message"
        placeholder="Start a message"
        color="primary"
        rows="1"
        :loading="loading"
        autocomplete
        auto-grow
        no-resize
        hide-details
        solo
        flat
      ></v-textarea>
    </div>
    <div class="entry--button">
      <v-btn
        :loading="loading"
        @click.stop="!loading && sendMessage()"
        depressed
      >
        <v-icon>{{ icon.mdiSend }}</v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { mdiSend } from '@mdi/js'
  import SharePost from '@/components/SharePost'
  import ShareProduct from '@/components/ShareProduct'
  import { isEmptyObject } from '@/util'

  export default {

    name: 'MessageBar',

    components: {
      SharePost,
      ShareProduct
    },

    props: [
      'action',
      'initialMessage'
    ],

    data() {
      return {
        icon: { mdiSend },
        componentLoaded: false,
        message: this.initialMessage || '',
        sharePost: null,
        shareProduct: null
      }
    },

    computed: {
      ...mapGetters([
        'loading',
      ]),
      routeShare() { return this.$route.query.share || '' },
      sharePostID() { return this.$route.query.sharePost },
      shareProductID() { return this.$route.query.shareProduct },
      showMeta() { return Boolean(this.sharePost || this.shareProduct) }
    },

    mounted() {
      this.message = this.message + this.routeShare

      let loader = null

      if (this.sharePostID) {
        loader = this.loadSharePost()
      } else if (this.shareProductID) {
        loader = this.loadShareProduct()
      }

      if (loader) {
        loader.then(() => this.componentLoaded = true)
      } else {
        this.componentLoaded = true
      }
    },

    methods: {
      ...mapActions([
        'getPost',
        'getProduct'
      ]),
      loadSharePost() {
        return this.getPost(this.sharePostID)
          .then(post => this.sharePost = {id: post.id, ...post.data()})
      },
      loadShareProduct() {
        return this.getProduct(this.shareProductID)
          .then(product => this.shareProduct = product)
      },
      resetMessaging() {
        if (!isEmptyObject(this.$route.query)) {
          this.$router.replace({
            ...this.$route,
            query: null
          })
        }
        this.sharePost = null
        this.shareProduct = null
        this.message = ''
      },
      sendMessage() {
        const sharePostID = this.sharePost && this.sharePost.id
        const shareProductID = this.shareProduct && this.shareProduct.id

        if (
          this.message ||
          sharePostID ||
          shareProductID
        ) {
          const message = {
            text: this.message,
            media: '',
            post: sharePostID,
            product: shareProductID
          }
          this.resetMessaging()
          this.$store.dispatch('setLoading', true)
          this.$store.dispatch(this.action, message)
            .then(() => this.$emit('message-sent'))
            .then(() => this.$store.dispatch('setLoading', false))
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_colors.scss';

  .v-sheet.entry {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: map-get($shades, "white");
    border-top: 1px solid map-get($grey, "lighten-2");
    bottom: 56px;
    left: 0;
    padding: 0 12px;
    position: fixed;
    width: 100%;

    .col {
      padding-top: 0;
      padding-bottom: 0;
    }

    .entry--meta {
      width: 100%;
      text-align: left;
    }

    .entry--text {
      flex: 1;
    }
    .entry--button {
      width: 88px;
    }
  }

  .v-main {
    padding-bottom: (56px + 48px) !important;
  }
  .v-main.meta-bar {
    padding-bottom: (56px + 48px + 58px + 16px) !important;
  }
</style>
