<template>
  <v-dialog
    id="loginDialog"
    v-model="show"
    max-width="500"
    width="500"
  >
    <v-card class="elevation-12" v-bind:class="{ shake: error }">
      <v-alert v-if="error" type="error" class="ma-0" dense>{{ error.message }}</v-alert>
      <v-form v-if="!resetForm" ref="loginForm" method="post" @submit.prevent="onSignin">
        <v-card-text>
          <v-text-field
            id="email"
            label="Email"
            name="email"
            v-model="email"
            type="email"
            :prepend-icon="icon.mdiAccount"
            required
          ></v-text-field>
          <v-text-field
            id="password"
            label="Password"
            name="password"
            v-model="password"
            :append-icon="showPass ? icon.mdiEye : icon.mdiEyeOff"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :prepend-icon="icon.mdiLock"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="resetForm = true"
          >Forgotten password?</v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            depressed
            :loading="loading"
            color="#7070a0"
            type="submit"
            dark
          >Login</v-btn>
        </v-card-actions>
      </v-form>
      <v-form v-else method="post" ref="loginResetForm" @submit.prevent="reset">
        <v-card-text>
          <p>Please enter the email address for your account and we'll
          send you a link to reset your password.</p>
          <v-text-field
            id="email"
            label="Email"
            name="email"
            v-model="resetEmail"
            type="email"
            :prepend-icon="icon.mdiAccount"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click.stop="resetForm = false"
          >
            <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
            Back
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            depressed
            :loading="loading"
            color="#7070a0"
            type="submit"
            dark
          >Reset password</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    mdiAccount,
    mdiArrowLeft,
    mdiEye,
    mdiEyeOff,
    mdiLock
  } from '@mdi/js'

  export default {
    data() {
      return {
        icon: {
          mdiAccount,
          mdiArrowLeft,
          mdiEye,
          mdiEyeOff,
          mdiLock
        },
        showPass: false,
        resetForm: false,
        email: '',
        password: '',
        resetEmail: ''
      }
    },
    computed: {
      show: {
        get () {
          return this.$store.getters.showLogin
        },
        set (value) {
          if (!value) {
            this.$store.dispatch('clearError')
            this.resetForm = false
          }
          return this.$store.dispatch('setShowLogin', value)
        }
      },
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },
    methods: {
      onSignin () {
        if (!this.loading && this.email && this.password) {
          this.$store.dispatch('clearError')
          this.$store.dispatch('signUserIn', {
            email: this.email,
            password: this.password
          })
            .then(() => {
              this.$refs.loginForm.reset()
              if (this.$route.query.next || this.$route.name !== 'stream') {
                this.$router.push(this.$route.query.next || { name: 'stream' }, () => {})
              }
            })
        }
      },
      reset() {
        if (!this.loading) {
          this.$store.dispatch('clearError')
          this.$store.dispatch('resetPasswordWithEmail', this.resetEmail)
            .then(() => {
              this.$refs.loginResetForm.reset()
              this.resetForm = false
              this.$store.dispatch('triggerSnackbar', 'Password reset email sent.')
            })
        }
      }
    }
  }
</script>