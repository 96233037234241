<template>
  <form method="get" @submit.prevent="$router.push({name: 'search', query: {q: query}}, () => {})">
    <v-toolbar
      dense
      class="search-bar elevation-0"
    >
      <v-text-field
        v-model="query"
        id="search-query"
        placeholder="Search"
        type="search"
        :loading="searchLoading"
        :prepend-icon="icon.mdiMagnify"
        autocomplete="off"
        hide-details
        solo
        flat
      ></v-text-field>
      <v-btn
        v-if="user"
        color="#7070a0"
        :to="{name: 'discover'}"
        depressed
        dark
        x-small
      >
        <v-icon left>{{ icon.mdiEye }}</v-icon>
        Discover
      </v-btn>
    </v-toolbar>
  </form>
</template>

<script>
  import { mdiEye, mdiMagnify } from '@mdi/js'
  import { mapGetters } from 'vuex'

  export default {

    name: 'SearchBar',

    data() {
      return {
        icon: { mdiEye, mdiMagnify },
        query: null
      }
    },

    computed: {
      routeQuery() {
        return this.$route.query.q
      },
      ...mapGetters([
        'searchLoading'
      ])
    },

    watch: {
      routeQuery(value) {
        this.query = value
        this.$store.dispatch('executeSearch', this.query)
      }
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_colors.scss';

  .v-sheet.search-bar {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 10;
    overflow: hidden;
    border-bottom: 1px solid map-get($grey, "lighten-2");

    .search-results {
      box-sizing: border-box;
      width: 90%;
      position: absolute;
      top: 48px;
      left: 5%;
    }
  }
</style>
