<template>
  <v-card v-if="product" :id="product.id" ref="product" class="product">
    <AccountHeaderSmall :account="product.account">
      <v-menu
        v-if="user && user.account === product.account"
        z-index="1"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            tile
          >
            <v-icon>{{ icon.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            :to="{name: 'edit-product', params:{account: product.account, product_id: product.id}}"
          >
            <v-list-item-title>Edit product</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click.stop="confirmDelete(product.account, product.id, product.title)"
          >
            <v-list-item-title>Delete product</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </AccountHeaderSmall>
    <v-img
      v-if="mediaSrc"
      :src="mediaSrc"
      class="grey lighten-2"
      min-height="300"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-title>{{ product.title }}</v-card-title>
    <v-card-text
      v-if="product.description"
      class="description collapsed"
      @click.stop="toggleHeight">
      <nl2br tag="p" :text="product.description"></nl2br>
    </v-card-text>
    <div
      v-if="showButton"
      class="read-more-btn">
      <v-btn
        @click.stop="toggleHeight"
        type="button"
        x-small
        text
      >Read more&hellip;</v-btn>
    </div>
    <v-card-text>
      <div v-if="product.tags.length" class="d-flex flex-wrap justify-center">
        <v-chip
          v-for="tag in product.tags"
          :key="tag"
          class="ma-1"
          x-small
        >
          {{ tag }}
        </v-chip>
      </div>
    </v-card-text>
    <v-card-text v-if="user">
      <v-text-field
        label="Quantity"
        required
        :error-messages="quantityErrors"
        v-model.number="$v.form.quantity.$model"
        @input="$v.form.quantity.$touch()"
        @blur="$v.form.quantity.$touch()"
      ></v-text-field>
      <v-select
        v-for="(select, index) in product.customOptions"
        :key="index"
        :label="select.description"
        :items="select.options"
        required
        v-model.trim="$v.form[select.description].$model"
        @input="$v.form[select.description].$touch()"
        @blur="$v.form[select.description].$touch()"
        :error-messages="requiredError($v.form[select.description], select.description)"
      ></v-select>
    </v-card-text>
    <v-card-text>
      <div class="text-right pt-2">
        <div class="headline">{{ displayPrice }}</div>
        <div>
          {{ derivedPrice.currency }}
          {{ product.unitSize ? '- ' + product.unitSize : '' }}
        </div>
        <div v-if="product.deriveStock(optionSearch)">
          In stock: {{ product.deriveStock(optionSearch) }}
        </div>
        <div v-else><strong>Out of stock</strong></div>
      </div>
    </v-card-text>
    <v-card-actions v-if="user">
      <v-btn
        small
        depressed
        color="#7070a0"
        class="my-1 white--text"
        @click="addToWishlist(product, form, account && account.currency)">
        <v-icon left>{{ icon.mdiPlus }}</v-icon>
        Add to wishlist
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn
        :disabled="product.deriveStock(optionSearch) <= 0"
        small
        depressed
        color="#7070a0"
        class="my-1 white--text"
        @click="addToCart(product, form, account && account.currency)">
        <v-icon left>{{ icon.mdiPlus }}</v-icon>
        Add to cart
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <div class="flex-grow-1"></div>

      <Share type="product" :item="product" :media="mediaSrc" />
    </v-card-actions>
    <v-dialog
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to remove the product "<span class="font-weight-bold">{{ confirm.description }}</span>"?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="resetConfirm"
          >
            No
          </v-btn>

          <v-btn
            text
            @click.stop="confirm.onConfirm"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, decimal } from 'vuelidate/lib/validators'
  import addto from '@/mixins/addto'
  import Nl2br from 'vue-nl2br'
  import Share from '@/components/Share'
  import AccountHeaderSmall from '@/components/AccountHeaderSmall'
  import { getImageURL } from '@/image-cdn'
  import {
    mdiDotsVertical,
    mdiPlus,
    mdiStarOutline,
  } from '@mdi/js'

  export default {
    mixins: [
      validationMixin,
      addto
    ],

    components: {
      AccountHeaderSmall,
      Nl2br,
      Share
    },

    name: 'StreamProduct',

    data() {
      return {
        icon: {
          mdiDotsVertical,
          mdiPlus,
          mdiStarOutline
        },
        form: {
          quantity: 1
        },
        accountMedia: null,
        mediaSrc: null,
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        },
        showButton: false
      }
    },

    validations() {
      const validations = {
        form: {
          quantity: {
            required,
            decimal
          }
        }
      }

      if (this.product.customOptions) {
        this.product.customOptions.forEach(custom => {
          this.$set(this.form, custom.description, "")
          validations.form[custom.description] = { required }
        })
      }

      return validations
    },

    props: [
      'product'
    ],

    computed: {
      ...mapGetters([
        'account'
      ]),
      quantityErrors () {
        const errors = [],
              field = this.$v.form.quantity
        if (!field.$dirty) return errors
        !field.required && errors.push('Quantity is required')
        !field.decimal && errors.push('Quantity must be decimal')
        return errors
      },
      routeProduct() {
        return this.$route.params.product_id
      },
      derivedPrice() {
        return this.product.derivePrice(
          this.account && this.account.currency,
          this.optionSearch
        )
      },
      displayPrice() {
        return this.derivedPrice ? Number(this.derivedPrice.unitPrice).toLocaleString(undefined, {
          style: "currency",
          currency: this.derivedPrice.currency
        }) : ''
      },
      optionSearch() {
        return Object.entries(this.form).reduce((acc, [key, value]) => {
          key = key.toLowerCase()
          if (key !== 'quantity') {
            acc[key] = value
          }
          return acc
        }, {})
      }
    },

    mounted() {
      this.mediaSrc = this.product.hasMedia ? getImageURL(`products/${this.product.account}/${this.product.id}`, 600, 600, this.product.updated || this.product.created) : undefined
    },

    updated() {
      if ('product' in this.$refs) {
        const collapse = this.$refs.product.$el.querySelector('.v-card__text.description')
        const heightDiff = collapse && collapse.scrollHeight && collapse.offsetHeight ?
          Boolean(collapse.scrollHeight - collapse.offsetHeight) :
          false

        if (heightDiff) this.showButton = heightDiff
      }
    },

    methods: {
      requiredError(field, label) {
        return field.$dirty && !field.required ? label + ' is required' : ''
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmDelete(account, productId, description) {
        const self = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            self.$store.dispatch('deleteProduct', {
              account,
              productId
            })
            this.$emit('deleted', productId)
            self.resetConfirm()
          }
        }
      },
      toggleHeight() {
        if (this.$refs && 'product' in this.$refs) {
          this.$refs.product.$el
            .querySelector('.v-card__text.description')
            .classList.toggle('collapsed')
        }
      }
    }
  }
</script>

<style lang="scss">
  .v-list.custom-options {
    padding: 0;
  }
  .v-list-item.custom-option {
    padding: 0;

    .v-list-item__content {
      align-self: flex-start;

      li {
        line-height: 1.375rem;
      }
    }

    .description {
      text-align: right;
      justify-content: right;
      flex: 0 0 30%;
    }
  }
  .v-card__actions form.purchase-form {
    flex: 1;
  }

  .v-card {
    .v-card__title,
    .v-card__text {
      word-break: normal;
      overflow-wrap: break-word;
    }

    .v-card__text.description {
      transition-property: padding max-height;
      transition-delay: 0;
      transition-duration: 0.2s;

      height: auto;
      max-height: 1000rem;

      padding-bottom: 0;

      &.collapsed {
        max-height: 5.6rem; // 4 * 1.4
        overflow-y: hidden;
      }
    }

    .read-more-btn {
      text-align: right;
      padding-right: 8px;
    }
  }
</style>
