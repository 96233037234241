<template>
  <v-dialog
    v-model="mutableValue"
    @input="$emit('input', mutableValue)"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">Are you sure?</v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn type="button" text @click="$emit('input', false)">No</v-btn>
        <v-btn type="button" text @click="$emit('confirm')">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    name: 'ConfirmDialog',

    props: {
      value: Boolean
    },

    data() {
      return {
        mutableValue: this.value
      }
    }
  }
</script>
