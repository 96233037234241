<template>
  <header v-if="componentLoaded">
    <v-btn class="back" text :to="{name: 'messages', params: {account: currentAccount}}" exact>
      <v-icon left>{{ icon.mdiArrowLeft }}</v-icon>
      Back to messages
    </v-btn>
    <h1 class="title d-flex my-2">
      <v-avatar :color="account.mediaSrc ? null : '#7070a0'" size="56" class="mr-3">
        <v-img v-if="account.mediaSrc" :src="account.mediaSrc" :alt="account.account"></v-img>
        <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
      </v-avatar>
      <div>
        {{ account.legalName }}
        <div class="body-2 text--secondary font-weight-regular">@{{ account.account }}</div>
      </div>
    </h1>
  </header>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getImageURL } from '@/image-cdn'
  import { mdiArrowLeft } from '@mdi/js'

  export default {

    name: 'MessageHeader',

    props: [
      'currentAccount',
      'displayAccount'
    ],

    data() {
      return {
        icon: { mdiArrowLeft },
        componentLoaded: false,
        account: {}
      }
    },

    mounted() {
      this.getAccount(this.displayAccount)
        .then(account => {
          this.account = account
          this.account.mediaSrc = account.media ? getImageURL(account.media, 80, 80, account.updated || account.created) : null
        })
        .then(() => this.componentLoaded = true)
    },

    methods: {
      ...mapActions(['getAccount'])
    }
  }
</script>

<style lang="scss">
</style>
