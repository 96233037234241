<template>
  <v-menu left top offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>{{ icon.mdiShareVariant }}</v-icon>
      </v-btn>
    </template>

    <v-card class="body-2">
      <v-list dense class="share-internal">
        <v-list-item
          v-if="isMerchant"
          :to="{name: 'messages-followers', params: {account: user.account}, query: this.isPost ? {sharePost: item.id} : {shareProduct: item.id}}">
          <v-list-item-avatar size="24">
            <v-icon>{{ icon.mdiMessageText }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            Send to followers
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="hasClipboardAPI"
          @click="copyToClipboard"
        >
          <v-list-item-avatar size="24">
            <v-icon>{{ icon.mdiLinkVariant }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="isPost">
            Copy link to post
          </v-list-item-content>
          <v-list-item-content v-else>
            Copy link to product
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="hasShareAPI">
        <v-divider />
        <v-list dense class="share-native">
          <v-list-item
            @click="triggerShareAPI"
          >
            <v-list-item-avatar size="24">
              <v-icon>{{ icon.mdiShareVariant }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Share via…
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-else>
        <v-divider />
        <v-list dense class="share-social">
          <v-list-item
            v-for="(data, network) in social"
            :key="network"
            :href="getSharer(data)"
            @click="logEvent"
            target="_blank"
          >
            <v-list-item-avatar size="24">
              <v-icon>{{ data.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Share via {{ network }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import {
    mdiShareVariant,
    mdiMessageText,
    mdiLinkVariant,
    mdiEmail,
    mdiTwitter,
    mdiFacebook,
    mdiLinkedin,
    mdiReddit
  } from '@mdi/js'
  import fb from '@/firebase'

  export default {

    name: 'Share',

    props: [
      "type",
      "item",
      "media"
    ],

    data: () => ({
      icon: {
        mdiShareVariant,
        mdiMessageText,
        mdiLinkVariant,
        mdiEmail,
        mdiTwitter,
        mdiFacebook,
        mdiLinkedin,
        mdiReddit
      },
      social: {
        email: {
          icon: mdiEmail,
          sharer: "mailto:?subject=@text&body=@url"
        },
        Twitter: {
          icon: mdiTwitter,
          sharer: "https://twitter.com/intent/tweet?text=@text&url=@url"
        },
        Facebook: {
          icon: mdiFacebook,
          sharer: "https://www.facebook.com/sharer/sharer.php?u=@url&title=@text"
        },
        LinkedIn: {
          icon: mdiLinkedin,
          sharer: "https://www.linkedin.com/shareArticle?url=@url&title=@text"
        },
        Reddit: {
          icon: mdiReddit,
          sharer: "https://www.reddit.com/submit?url=@url&title=@text"
        }
      }
    }),

    computed: {
      ...mapGetters([
        'account'
      ]),
      isMerchant() { return Boolean(this.account && this.account.type === 'seller') },
      isPost() { return Boolean(this.type === 'post') },
      hasClipboardAPI() { return Boolean(navigator.clipboard) },
      hasShareAPI() { return Boolean(navigator.share) },
      shareUrl() {
        const host = `https://${window.location.host}`
        const path = this.isPost ?
          `/${this.item.account}/posts/${this.item.id}` :
          `/${this.item.account}/products/${this.item.id}`

        return host + path
      },
      shareTitle() {
        return this.item.title
      },
      shareObject() {
        return {
          text: this.shareTitle,
          url: this.shareUrl
        }
      }
    },

    methods: {
      ...mapActions([
        'triggerSnackbar'
      ]),
      copyToClipboard() {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(this.shareUrl)
            .then(() => {
              this.logEvent()
              this.triggerSnackbar('Copied to clipboard')
            })
        }
      },
      triggerShareAPI() {
        if (navigator.canShare) {
          this.srcToFile(this.media, this.media.split('/').pop())
            .then(file => {
              if (navigator.canShare({ files: [ file ] })) {
                return navigator.share({
                  files: [ file ],
                  ...this.shareObject
                })
                  .then(() => {
                    this.logEvent()
                    this.triggerSnackbar('Shared successfully')
                  })
                  .catch(() => {})
              }
            })
        }

        if (navigator.share) {
          return navigator.share(this.shareObject)
            .then(() => {
              this.logEvent()
              this.triggerSnackbar('Shared successfully')
            })
            .catch(() => {})
        }
      },
      logEvent() {
        fb.analytics.logEvent('share', {
          content_type: this.isPost ? 'post' : 'product',
          item_id: this.item.id
        })
      },
      getSharer(network) {
        return network.sharer
          .replace(/@url/g, encodeURIComponent(this.shareObject.url))
          .replace(/@text/g, encodeURIComponent(this.shareObject.text))
      },
      srcToFile(src, fileName) {
        return fetch(src)
          .then(res => res.arrayBuffer())
          .then(buf => new File([buf], fileName))
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
