<template>
  <v-card
    v-if="isPostActive || isVisibleProcessing"
    :id="item.id"
  >
    <AccountHeaderSmall dense :account="item.account">
      <v-icon v-if="isPinnedPost">{{ icon.mdiPin }}</v-icon>
      <v-menu
        v-if="user && user.account === item.account"
        z-index="1"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            tile
          >
            <v-icon>{{ icon.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-if="isPinnedPost"
            @click="pinPost(null)"
          >
            <v-list-item-title>Unpin this post</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            @click="pinPost(item.id)"
          >
            <v-list-item-title>Pin this post</v-list-item-title>
          </v-list-item>
          <v-list-item
            :to="{name: 'manage-products', params:{account: item.account, post_id: item.id}}"
          >
            <v-list-item-title>Manage products</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isPostActive"
            @click.stop="confirmDelete(item.id, item.title)"
          >
            <v-list-item-title>Delete post</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </AccountHeaderSmall>
    <v-card flat tile class="post-content">
      <div v-if="isVisibleProcessing">
        <div class="finalising">
          <div class="title grey--text">Post finalising&hellip;</div>
          <div class="body-2 grey--text">We'll only be a little while longer</div>
        </div>
        <v-skeleton-loader
          type="image"
          tile
        ></v-skeleton-loader>
        <v-card-text class="grey--text text--lighten-1"><span>{{ item.title }}</span></v-card-text>
      </div>
      <div
        v-else
      >
        <v-img
          v-if="item.mediaType === 'image' && mediaSrc"
          :src="mediaSrc"
          class="grey lighten-2"
          min-height="300"
          @click="activateOverlay"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div v-if="item.mediaType === 'video' && mediaSrc">
          <VideoPlayer
            :src="mediaSrc"
            ref="videoPlayer"
            @play="videoPlaying = true"
            @playing="videoPlaying = true"
            @pause="videoPlaying = false"
          />
        </div>
        <v-card-text @click="activateOverlay">{{ item.title }}</v-card-text>
        <v-overlay
          v-if="isPostActive"
          :value="overlay"
          absolute
          class="d-block"
          color="black"
          opacity="0.7"
          z-index="1"
          ref="overlay"
        >
          <v-card-actions>
            <div class="flex-grow-1 text-center justify-center">
              <span v-if="!user" class="login-notice caption">
                Please <router-link :to="{name:'signup'}">signup</router-link>
                or <a href="#" @click.stop="$store.dispatch('setShowLogin', true)">login</a>
                to make purchases
              </span>
              <span v-else class="login-notice caption">
                Please select an item to add to your cart or wishlist
              </span>
            </div>
            <v-btn
              icon
              dark
              x-small
              @click="closeOverlay"
            >
              <v-icon>{{ icon.mdiClose }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <v-sheet tag="section" class="transparent" v-if="!user || !selectedProduct">
              <v-list class="transparent">
                <v-list-item-group v-model="selectedProduct">
                  <v-list-item
                    v-for="item in products"
                    :key="item.id"
                    :value="item"
                  >
                    <v-list-item-avatar tile size="80" class="ml-4 mr-6">
                      <v-img :src="item.mediaSrc"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.derivePrice && displayPrice(item.derivePrice(account && account.currency)) }}
                        {{ item.derivePrice && item.derivePrice(account && account.currency) ? item.derivePrice(account && account.currency).currency : '' }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="item.deriveStock && item.deriveStock(optionSearch)">
                        In stock: {{ item.deriveStock && item.deriveStock(optionSearch) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        Out of stock
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
            <v-sheet tag="section" class="transparent" v-else-if="user && selectedProduct">
              <v-card-text class="py-1">
                <h2 class="product-title subtitle-1 font-weight-bold pb-3">{{ selectedProduct.title }}</h2>
                <div>
                  <v-text-field
                    label="Quantity"
                    required
                    dense
                    :error-messages="quantityErrors"
                    v-model.number="$v.selectedOptions.quantity.$model"
                    @input="$v.selectedOptions.quantity.$touch()"
                    @blur="$v.selectedOptions.quantity.$touch()"
                  ></v-text-field>
                  <div v-if="selectedProduct.customOptions.length < 4">
                    <v-select
                      v-for="(select, index) in selectedProduct.customOptions"
                      :key="index"
                      :label="select.description"
                      :items="select.options"
                      required
                      dense
                      v-model.trim="$v.selectedOptions[select.description].$model"
                      @input="$v.selectedOptions[select.description].$touch()"
                      @blur="$v.selectedOptions[select.description].$touch()"
                      :error-messages="requiredError($v.selectedOptions[select.description], select.description)"
                    ></v-select>
                  </div>
                  <v-row v-else>
                    <v-col>
                      <v-select
                        v-for="(select, index) in selectedProduct.customOptions.slice(0, 3)"
                        :key="index"
                        :label="select.description"
                        :items="select.options"
                        required
                        dense
                        v-model.trim="$v.selectedOptions[select.description].$model"
                        @input="$v.selectedOptions[select.description].$touch()"
                        @blur="$v.selectedOptions[select.description].$touch()"
                        :error-messages="requiredError($v.selectedOptions[select.description], select.description)"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        v-for="(select, index) in selectedProduct.customOptions.slice(3)"
                        :key="index + 3"
                        :label="select.description"
                        :items="select.options"
                        required
                        dense
                        v-model.trim="$v.selectedOptions[select.description].$model"
                        @input="$v.selectedOptions[select.description].$touch()"
                        @blur="$v.selectedOptions[select.description].$touch()"
                        :error-messages="requiredError($v.selectedOptions[select.description], select.description)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
                <div class="d-flex">
                  <div v-if="selectedProduct.stock" class="pt-2">
                    In stock: {{ selectedProduct.deriveStock(optionSearch) }}
                  </div>
                  <div v-else class="pt-2"><strong>Out of stock</strong></div>

                  <div class="flex-grow-1"></div>

                  <div class="text-right pt-2">
                    <div class="title">{{ displayPrice(selectedProduct.derivePrice(account && account.currency, optionSearch)) }}</div>
                    <div>
                      {{ selectedProduct.derivePrice(account && account.currency) ? selectedProduct.derivePrice(account && account.currency, optionSearch).currency : '' }}
                      {{ selectedProduct.unitSize ? '- ' + selectedProduct.unitSize : '' }}
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="2">
                    <v-btn
                      small depressed dark text class="my-1"
                      @click.stop="deselectProduct"
                    >Back</v-btn>
                  </v-col>
                  <v-col align="right">
                    <v-btn
                      small
                      depressed
                      dark
                      color="#7070a0"
                      class="mx-2 my-1"
                      @click="addToWishlist(selectedProduct, selectedOptions, account && account.currency, deselectProduct)">
                      <v-icon left>{{ icon.mdiPlus }}</v-icon>
                      Wishlist
                    </v-btn>
                    <v-btn
                      :disabled="selectedProduct.deriveStock(optionSearch) <= 0"
                      small
                      depressed
                      dark
                      color="#7070a0"
                      class="mx-2 my-1"
                      @click="addToCart(selectedProduct, selectedOptions, account && account.currency, deselectProduct)">
                      <v-icon left>{{ icon.mdiPlus }}</v-icon>
                      Cart
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-sheet>
          </v-expand-transition>
        </v-overlay>
      </div>
    </v-card>
    <v-card-actions v-if="isPostActive">
      <v-btn v-if="products.length" icon @click.stop="toggleOverlay">
        <v-icon>{{ icon.mdiShopping }}</v-icon>
      </v-btn>

      <div class="flex-grow-1"></div>

      <Share type="post" :item="item" :media="mediaSrc" />
    </v-card-actions>
    <v-dialog
      v-if="isPostActive"
      v-model="confirm.active"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to remove the post "<span class="font-weight-bold">{{ confirm.description }}</span>"?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="resetConfirm"
          >
            No
          </v-btn>

          <v-btn
            text
            @click.stop="confirm.onConfirm"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, decimal } from 'vuelidate/lib/validators'
  import fb from '@/firebase'
  import Product from '@/aggroot/product'
  import { getImageURL } from '@/image-cdn'
  import addto from '@/mixins/addto'
  import { postStatus } from '@/enums'

  import {
    mdiDotsVertical,
    mdiPlayCircle,
    mdiShareVariant,
    mdiShopping,
    mdiMessageText,
    mdiClose,
    mdiPlus,
    mdiPin
  } from '@mdi/js'
  import AccountHeaderSmall from '@/components/AccountHeaderSmall'
  import Share from '@/components/Share'
  import VideoPlayer from '@/components/Video'

  export default {
    mixins: [
      validationMixin,
      addto
    ],
    components: {
      AccountHeaderSmall,
      Share,
      VideoPlayer
    },
    props: {
      item: Object,
      isPinnedPost: Boolean
    },
    data() {
      return {
        postStatus,
        icon: {
          mdiDotsVertical,
          mdiPlayCircle,
          mdiShareVariant,
          mdiMessageText,
          mdiShopping,
          mdiClose,
          mdiPlus,
          mdiPin
        },
        mediaSrc: '',
        overlay: false,
        primitiveProducts: [],
        products: [],
        selectedProduct: null,
        selectedOptions: {
          quantity: 1
        },
        initialOptions: {
          quantity: 1
        },
        confirm: {
          active: false,
          description: "",
          onConfirm: null
        },
        videoPlaying: false
      }
    },
    validations() {
      const validations = {
        selectedOptions: {
          quantity: {
            required,
            decimal
          }
        }
      }

      if (this.selectedProduct && this.selectedProduct.customOptions) {
        this.selectedProduct.customOptions.forEach(custom => {
          this.$set(this.selectedOptions, custom.description, "")
          validations.selectedOptions[custom.description] = { required }
        })
      }

      return validations
    },
    computed: {
      ...mapGetters([
        'account',
        'user',
      ]),
      isMerchant() {
        return this.account.type === 'seller'
      },
      quantityErrors () {
        const errors = [],
              field = this.$v.selectedOptions.quantity
        if (!field.$dirty) return errors
        !field.required && errors.push('Quantity is required')
        !field.decimal && errors.push('Quantity must be decimal')
        return errors
      },
      isPostActive() {
        return Boolean(
          this.item.status === this.postStatus.ACTIVE ||
          this.item.status === undefined
        )
      },
      isVisibleProcessing() {
        return Boolean(
          this.user &&  // User is logged in
          this.user.account === this.item.account &&
          this.item.status === this.postStatus.PROCESSING
        )
      },
      videoPlayer() {
        return this.$refs.videoPlayer && this.$refs.videoPlayer.player
      },
      optionSearch() {
        return Object.entries(this.selectedOptions).reduce((acc, [key, value]) => {
          key = key.toLowerCase()
          if (key !== 'quantity') {
            acc[key] = value
          }
          return acc
        }, {})
      }
    },
    watch: {
      isPostActive(value) {
        if (value) {
          this.populateMediaSrc()
        }
      },
      item: {
        immediate: true,
        handler(item) {
          this.populateMediaSrc()
          item.products = (item.products || []).filter(Boolean)
          if (item.products && item.products.length) {
            this.$bind(
              'primitiveProducts',
              fb.db.collection('products')
                .where(fb.FieldPath.documentId(), 'in', item.products)
            )
          }
        }
      },
      primitiveProducts(value) {
        if (value && Array.isArray(value)) {
          this.products = value.map(product => {
            product = Product.fromObj(product)
            product.mediaSrc = product.hasMedia && getImageURL(
              `/products/${ this.item.account }/${ product.id }`,
              160, 160,
              product.updated || product.created
            )
            return product
          })
        }
      }
    },
    methods: {
      ...mapActions([
        'setPinnedPost',
        'triggerSnackbar'
      ]),
      pinPost(postID) {
        return this.setPinnedPost({
          account: this.item.account,
          postID: postID
        })
        .then(() => this.$router.push({
          name: 'profile',
          params: {account: this.item.account},
          query: {
            t: + new Date()
          }
        }))
        .then(() => this.triggerSnackbar(postID ? 'Post pinned!' : 'Post unpinned!'))
      },
      displayPrice(price) {
        return Number(price.unitPrice).toLocaleString(undefined, {
          style: "currency",
          currency: price.currency
        })
      },
      isDescendant(parent, child) {
        let node = child.parentNode
        while (node) {
          if (node === parent) return true
          node = node.parentNode
        }
        return false
      },
      activateOverlay(e) {
        e.stopPropagation()

        if (this.isPostActive && this.products.length) {
          if (this.videoPlayer && this.videoPlaying) this.videoPlayer.pause()

          this.overlay = true
        }
      },
      closeOverlay(e) {
        e.stopPropagation()

        if (this.overlay) {
          if (this.videoPlayer && this.videoPlaying) this.videoPlayer.play()

          this.overlay = false
          this.deselectProduct()
        }
      },
      toggleOverlay(e) {
        if (this.overlay) {
          this.closeOverlay(e)
        } else {
          this.activateOverlay(e)
        }
      },
      requiredError(field, label) {
        return field.$dirty && !field.required ? label + ' is required' : ''
      },
      resetConfirm() {
        this.confirm = {
          active: false,
          description: "",
          onConfirm: null
        }
      },
      confirmDelete(postId, description) {
        const $app = this

        this.confirm = {
          active: true,
          description: description,
          onConfirm: () => {
            $app.$store.dispatch('deletePost', postId)
              .then(() => $app.$router.push({
                name: 'profile',
                params: {account: $app.item.account},
                query: {
                  t: + new Date()
                }
              }))
            $app.resetConfirm()
          }
        }
      },
      deselectProduct() {
        this.selectedOptions = this.initialOptions
        this.selectedProduct = null
        this.$v.$reset()
      },
      populateMediaSrc() {
        if (this.isPostActive) {
          if (this.item.mediaType === 'image') {
            this.mediaSrc = getImageURL(this.item.media, 600, 600, this.item.created)
          } else {
            fb.storage.ref(this.item.media)
              .getDownloadURL()
              .then(url => this.mediaSrc = url)
              .catch(error =>
                this.logError('Error getting download URL.', error)
              )
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-card {
    overflow-wrap: normal;

    .v-card__text {
      word-break: normal;
      overflow-wrap: break-word;
    }

    .finalising {
      position: absolute;
      text-align: center;
      top: 80px;
      width: 100%;
    }
  }
  .nuokka {
    width: 24px;
    height: 24px;
  }
  .login-notice {
    a {
      color: #FFE45C;
      font-weight: bold;
      text-decoration: none;
    }
  }
  .product-title {
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
