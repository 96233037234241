<template>
  <v-list-item
    v-if="mutableProduct && 'id' in mutableProduct"
    :id="mutableProduct.id"
    :value="mutableProduct.id"
  >
    <v-list-item-avatar v-if="mutableProduct.hasMedia" tile size="80" class="my-0">
      <v-img
        v-if="mutableProduct.hasMedia"
        :src="productMedia"
      ></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">
        {{ mutableProduct.title }}
      </v-list-item-title>
      <v-list-item-subtitle>SKU: {{ mutableProduct.code }} — Unit: {{ mutableProduct.unitSize }}</v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="editButton || deleteButton"
        class="text-right"
      >
        <v-btn-toggle
          dense
          multiple
        >
          <v-btn v-if="editButton" icon :to="editRoute">
            <v-icon>{{ icon.mdiFileDocumentEditOutline }}</v-icon>
          </v-btn>
          <v-btn v-if="editButton && mutableProduct.hasVariants" icon :to="variantRoute">
            <v-icon>{{ icon.mdiFileDocumentMultipleOutline }}</v-icon>
          </v-btn>
          <v-btn v-if="deleteButton" icon @click.stop="$emit('delete', {id: mutableProduct.id, title: mutableProduct.title, hasMedia: mutableProduct.hasMedia})">
            <v-icon>{{ icon.mdiTrashCanOutline }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-content v-if="prices" class="prices">
      <v-list-item-subtitle>
        <div
          v-for="price in mutableProduct.prices"
          :key="price.currency"
        >
          {{ price.currency }} {{ parseFloat(price.unitPrice).toFixed(2) }}
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import {
    mdiFileDocumentEditOutline,
    mdiTrashCanOutline,
    mdiFileDocumentMultipleOutline
  } from '@mdi/js'
  import { getImageURL } from '@/image-cdn'
  import Product from '@/aggroot/product'

  export default {
    props: {
      product: Object,
      post: String,
      editButton: Boolean,
      deleteButton: Boolean,
      prices: Boolean
    },
    data() {
      return {
        icon: {
          mdiFileDocumentEditOutline,
          mdiFileDocumentMultipleOutline,
          mdiTrashCanOutline
        },
        productMedia: '',
        mutableProduct:
          this.product instanceof Product ?
            this.product :
            Product.fromObj(this.product)
      }
    },
    computed: {
      variantRoute() {
        return {
          name: 'edit-product-variants',
          params: { account: this.mutableProduct.account, product_id: this.mutableProduct.id }
        }
      },
      editRoute() {
        return {
          name: 'edit-product',
          params: { account: this.mutableProduct.account, product_id: this.mutableProduct.id },
          query: this.post ? { post: this.post } : undefined
        }
      }
    },
    watch: {
      mutableProduct: {
        immediate: true,
        handler(product) {
          this.loadImage(product)
        }
      }
    },
    methods: {
      loadImage(product) {
        if (product && product.hasMedia && !this.productMedia) {
          this.$store.dispatch('getAccountProductImage', {
            account: product.account,
            productId: product.id
          }).then(url => {
            this.productMedia = getImageURL(url, 80, 80, product.updated || product.created)
          }).catch(error => {
            this.logError('Error loading image', {error: error.message})
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-list-item__content {
    align-self: start;
  }
  .prices {
    flex: 0 1 30%;
    text-align: right;
  }
</style>
