<template>
  <v-card>
    <v-card-text>
      Your personalised feed of content seems a bit short. Why not…
    </v-card-text>
    <v-list>
      <v-list-item
        v-for="(section, index) in sections"
        :key="index"
      >
        <v-list-item-content class="section-button">
          <v-btn
            color="#7070a0"
            :to="section.to ? section.to(user) : null"
            @click.stop="section.click ? section.click(user) : null"
            depressed
            dark
          >
            <v-icon left>{{ section.icon }}</v-icon>
            {{ section.title }}
          </v-btn>
        </v-list-item-content>
        <v-list-item-content class="section-text caption">
          {{ section.text }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
  import {
    mdiEye,
    mdiFeatureSearch,
    mdiCompass
  } from '@mdi/js'

  export default {

    name: 'DiscoveryCard',

    data: () => ({
      sections: [{
        title: 'Search',
        icon: mdiFeatureSearch,
        text: 'Find the accounts and tags that suit your interests…',
        to: null,
        click: () => document.getElementById("search-query").focus()
      }, {
        title: 'Discover',
        icon: mdiEye,
        text: 'View the full Nuokka stream, unfiltered…',
        to: () => ({name: 'discover'}),
        click: null
      }, {
        title: 'Explore',
        icon: mdiCompass,
        text: 'Investigate who the people you follow follow…',
        to: user => ({name: 'following', params: {account: user.account}}),
        click: null
      }]
    })
  }
</script>

<style lang="scss" scoped>
  .section-button {
    flex: 0 0 40%;
    margin-right: 16px;
  }
  .section-text {
    flex: 1;
  }
</style>
