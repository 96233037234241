<template>
  <v-autocomplete
    :items="categories"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
  </v-autocomplete>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: [
      'value'
    ],
    computed: {
      ...mapGetters(["taxCategories"]),
      categories() {
        return this.taxCategories
          .map(c => ({ value: c.product_tax_code, text: c.name }))
          .sort((a, b) => {
            var nameA = a.text.toLowerCase()
            var nameB = b.text.toLowerCase()

            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            return 0
          })
      }
    }
  }
</script>