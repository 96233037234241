<template>
  <v-card>
    <v-card-text class="text-center">{{ label }}</v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <slot></slot>
      <div class="flex-grow-1"></div>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {

    name: 'PromptCard',

    props: [
      "label",
      "ctaLabel",
      "ctaRoute"
    ]
  }
</script>

<style lang="scss" scoped>
</style>
