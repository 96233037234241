<template>
  <label class="media-upload" ref="filereader__label">
    <input
      name="file"
      type="file"
      :accept="accept"
      @change="filePreview"
    />
    <div class="placeholder" ref="filereader__btn">
      <div
        class="placeholder-inner"
        :style="previewHeightOutput()"
      >
        <v-img
          v-if="preview && fileType === 'image'"
          :src="preview"
          :height="previewHeight"
          contain
        ></v-img>
        <video
          v-else-if="preview && fileType === 'video'"
          width="100%"
          :height="previewHeight"
          preload="auto"
          playsinline
          loop
          disablePictureInPicture
          controlsList="nodownload"
          @contextmenu.prevent="() => false"
        >
          <source type="video/mp4" :src="preview" />
          Your browser does not support HTML video.
        </video>
        <v-row
          class="fill-height"
          align="center"
          justify="center"
          v-else
        >
          <v-icon
            v-if="icon"
            :class="label ? 'mr-2' : ''"
            :left="!!label"
            x-large
          >{{ icon }}</v-icon>
          <span v-if="label">{{ label }}</span>
        </v-row>
      </div>
    </div>
  </label>
</template>

<script>
  import loadImage from 'blueimp-load-image'
  import fb from '@/firebase'

  const imageTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png'
      ]

  const videoTypes = [
        'video/webm',
        'video/quicktime',
        'video/mp4'
      ]

  export default {
    model: {
      prop: 'previewProp',
      event: 'preview'
    },
    props: {
      'icon': {
        type: String
      },
      'label': {
        type: String
      },
      'type': {
        type: String
      },
      'previewProp': {
        type: String
      },
      'previewWidth': {
        type: Number
      },
      'previewHeight': {
        type: Number
      },
      'resizeWidth': {
        type: Number,
        default: 600
      },
      'resizeHeight': {
        type: Number,
        default: 600
      },
      'accept': {
        type: String,
        default: 'video/*,video/mp4,video/x-m4v,image/*'
      },
      'progressCallback': {
        type: Function
      }
    },
    data: function() {
      return {
        preview: this.previewProp,
        ph: this.previewHeight,
        fileType: this.type,
        fileData: null,
        uploadData: null,
        videoLoaded: false
      }
    },
    watch: {
      previewProp: function(val) {
        this.preview = val
      }
    },
    updated() {
      const video = this.$el.querySelector('video')

      if (video && !this.videoLoaded) {
        video.load()
        this.videoLoaded = true
      }
    },
    methods: {
      isValidFileType: (file, fileTypes) =>
        file && fileTypes.some(t => file.type === t),
      filePreview(e) {
        this.uploadValue = 0
        this.preview = null
        this.uploadData = null
        this.fileData = e.target.files[0]

        if (this.isValidFileType(this.fileData, imageTypes)) {
          // Image preview
          this.fileType = 'image'
          this.$store.dispatch('clearError')
          loadImage.parseMetaData(this.fileData, (data) => {
            let orientation = data.exif ? data.exif.get('Orientation') : 0;

            loadImage(
              this.fileData,
              (canvas) => {
                canvas.toBlob((blob) => {
                  this.preview = window.URL.createObjectURL(this.uploadData = blob)
                  this.ph = null

                  this.$emit('preview', this.preview)
                })
              },
              {
                canvas: true,
                maxWidth: this.resizeWidth,
                maxHeight: this.resizeHeight,
                orientation: orientation,
                crop: true
              }
            )
          })
        } else if (this.isValidFileType(this.fileData, videoTypes)) {
          // Video preview
          this.fileType = 'video'
          this.$store.dispatch('clearError')
          this.preview = window.URL.createObjectURL(this.uploadData = this.fileData)
          this.ph = null
          this.$emit('preview', this.preview)
        } else {
          this.$store.dispatch('setError', Error('Whoops: Uploads need to be images or video.'))
        }
      },
      upload(path, progressCallback) {
        this.logInfo('Uploading media', {path})

        const storageRef = fb.storage
          .ref(path + '.original')
          .put(this.uploadData)

        storageRef.on(
          'state_changed',
          this.progressCallback || progressCallback,
          error => {
            this.$store.dispatch('setLoading', false)
            this.$store.dispatch('setError', error)
            this.logError('Error on upload', error)
          },
          () => {
            this.uploadValue = 100
            this.$emit('upload-complete', {'type': this.fileType, 'data': this.uploadData})
          }
        )

        return storageRef.then(() => {
          return {'type': this.fileType, 'data': this.uploadData}
        })
        .catch(error => {
          this.logError('There was an issue during upload', {error: error.message})
        })
      },
      cleanup() {
        window.URL.revokeObjectURL(this.uploadData)
      },
      previewHeightOutput() {
        return this.ph ? `height:${this.ph}px;` : ''
      }
    },
    computed: {
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      }
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_colors.scss';

  .media-upload {
    outline: 1px dotted transparent;

    &:focus-within {
      outline-color: map-get($grey, "lighten-1");
    }

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .placeholder {
      & *:first-child {
        cursor: pointer;
      }

      & > .placeholder-inner {
        background-color: map-get($grey, "lighten-2");
        text-align: center;
      }
    }
  }
</style>
