<template>
  <v-select
    :items="currencies"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
  </v-select>
</template>

<script>
  export default {
    props: [
      'value'
    ],
    computed: {
      currencies() {
        return Object.entries(this.$store.getters.currencies)
          .map(([key, value]) => ({
            "value": key,
            "text": `${key} - ${value}`
          }))
      }
    }
  }
</script>