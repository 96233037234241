<template>
  <div class="text-center py-8">
    <v-progress-circular
      indeterminate
      color="#7070a0"
      class="ma-4"
    ></v-progress-circular>
    <p><slot>Please wait&hellip;</slot></p>
  </div>
</template>

<script>
  export default {

    name: 'PleaseWait',

    data: () => ({})
  }
</script>

<style lang="scss">
</style>
