<template>
  <div>
    <v-list-item v-if="account" :to="{name: 'profile', params: {account: item._id}}">
      <v-list-item-avatar :color="mediaSrc ? null : '#7070a0'" size="80">
        <v-img v-if="mediaSrc" :src="mediaSrc"></v-img>
        <span v-else class="white--text headline">{{ account.account[0].toUpperCase() }}</span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="body-2 font-weight-bold">{{ account.account }}</v-list-item-title>
        <v-list-item-subtitle>{{ account.legalName }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-if="this.user && account.account == this.user.account">
        <v-list-item-subtitle>
          <v-icon color="#7070a0">{{ icon.mdiAccount }}</v-icon> Your account
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-else-if="this.user && getFollow({follower: this.user.account, followType: 'account', followID: account.account})">
        <v-list-item-subtitle>
          <v-icon color="success">{{ icon.mdiAccount }}</v-icon> Following
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-else-if="this.user">
        <v-list-item-subtitle>
          <v-icon color="grey lighten-1">{{ icon.mdiAccount }}</v-icon> Not following
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="post" :to="{name: 'single-post', params: {account: post.account, post_id: item._id }}">
      <v-list-item-avatar tile size="80">
        <div class="v-responsive v-image grey lighten-2 justify-center" v-if="post.mediaType == 'video'">
          <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
          <video
            class="video"
            preload="auto"
            playsinline
            controlsList="nodownload"
            loop
            disablePictureInPicture
            @contextmenu.prevent="() => false"
          >
            <source type="video/mp4" :src="mediaSrc" />
            Your browser does not support HTML video.
          </video>
          <div class="v-responsive__content"></div>
        </div>
        <v-img v-if="post.mediaType == 'image'" :src="mediaSrc"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="body-2 font-weight-bold">{{ post.title }}</v-list-item-title>
        <v-list-item-subtitle>
          Post by <strong>{{ post.account }}</strong>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="product" :to="{name: 'single-product', params: {account: product.account, product_id: item._id }}">
      <v-list-item-avatar tile size="80">
        <v-img :src="mediaSrc"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="body-2 font-weight-bold">{{ product.title }}</v-list-item-title>
        <v-list-item-subtitle>
          Product by <strong>{{ product.account }}</strong>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="tag" :to="{name: 'tag-stream', params: {tag: tag.tag }}">
      <v-list-item-avatar size="80" tile color="indigo">
        <span class="white--text display-2 font-weight-bold">#</span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="body-2 font-weight-bold">{{ tag.tag }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-content v-if="this.user && getFollow({follower: this.user.account, followType: 'tag', followID: tag.tag})">
        <v-list-item-subtitle>
          <v-icon color="success">{{ icon.mdiTag }}</v-icon> Following
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-else>
        <v-list-item-subtitle>
          <v-icon color="grey lighten-1">{{ icon.mdiTag }}</v-icon> Not following
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import fb from '@/firebase'
  import { getImageURL } from '@/image-cdn'
  import { mdiAccount, mdiTag } from '@mdi/js'

  export default {

    name: 'SearchResultItem',

    props: [
      'item'
    ],

    data() {
      return {
        icon: { mdiAccount, mdiTag },
        mediaSrc: null,
        account: null,
        post: null,
        product: null,
        tag: null
      }
    },

    computed: {
      ...mapGetters([
        'getFollow'
      ])
    },

    watch: {
      item() {
        this.populateItem()
      }
    },

    mounted() {
      this.populateItem()
    },

    updated() {
      const video = this.$el.querySelector('video')

      if (video) {
        if (video.offsetWidth > video.offsetHeight) {
          video.style.height = '100%'
        } else {
          video.style.width = '100%'
        }
        video.load()
      }
    },

    methods: {
      reset() {
        this.account = this.post = this.product = null
      },
      populateItem() {
        this.reset()

        switch(this.item._index) {
          case "accounts": {
            this.$store
              .dispatch('getAccount', this.item._id)
              .then(account => {
                this.account = account
                if (this.account && this.account.media) {
                  this.mediaSrc = getImageURL(this.account.media, 80, 80, this.account.updated || this.account.created)
                }
              })
            break;
          }

          case "posts": {
            this.$store
              .dispatch('getPost', this.item._id)
              .then(post => {
                this.post = post && post.data()
                if (this.post) {
                  if (this.post.mediaType === 'image') {
                    this.mediaSrc = getImageURL(this.post.media, 80, 80, this.post.created)
                  } else {
                    fb.storage.ref(`${ this.post.media }`)
                      .getDownloadURL()
                      .then(url => {
                        this.mediaSrc = url
                      }).catch(error => {
                        this.logError('Error getting download URL.', error)
                      })
                  }
                }
              })
            break;
          }

          case "products": {
            this.$store
              .dispatch('getProduct', this.item._id)
              .then(product => {
                this.product = product
                if (this.product && this.product.hasMedia) {
                  this.mediaSrc = getImageURL(`products/${ this.product.account }/${ this.item._id }`, 80, 80, this.product.updated || this.product.created)
                }
              })
            break;
          }

          case "tags": {
            this.$store
              .dispatch('getConsolidatedTag', this.item._id)
              .then(tag => {
                this.tag = tag
              })
            break;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .video {
    position: absolute;
    z-index: -1;
    top: 0;
  }
</style>
