<template>
  <v-list dense light>
    <v-list-item :to="postLink">
      <v-list-item-avatar v-if="media">
        <v-img :src="media" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ product.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ product.account }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import { getImageURL } from '@/image-cdn'

  export default {

    name: 'SharePost',

    props: {
      product: Object,
      link: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      media: null
    }),

    computed: {
      postLink() {
        return this.link ? {
          name: 'single-product',
          params: {
            account: this.product.account,
            product_id: this.product.id
          }
        } : undefined
      }
    },

    mounted() {
      this.$nextTick(() =>
        this.media = getImageURL(
          `products/${this.product.account}/${this.product.id}`,
          600, 600,
          this.product.updated || this.product.created
        )
      )
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_colors.scss';

  .v-list {
    border: 1px solid map-get($grey, "lighten-2");
    padding: 0;
    margin: 8px 0;
  }
</style>
