<template>
  <v-list-item
    :class="{'message': true, 'mt-3': true, 'justify-end': isYou, 'is-you': isYou, 'd-flex': true, fullwidth}"
  >
    <v-card
      :color="isYou ? '#7070a0' : 'grey lighten-2'"
      :dark="isYou"
      :width="fullwidth ? '100%' : undefined"
    >
      <v-card-text v-if="showMeta">
        <SharePost v-if="sharePost" :post="sharePost" link />
        <ShareProduct v-if="shareProduct" :product="shareProduct" link />
      </v-card-text>
      <v-card-text
        v-if="message.text"
        :class="{'white--text': isYou, 'black--text': !isYou}"
      >
        <p v-html="preparedText" v-linkified></p>
      </v-card-text>
    </v-card>
    <div :class="{'text-right': isYou, 'date caption': true}">{{ displayDate }}</div>
  </v-list-item>
</template>

<script>
  import { mapActions } from 'vuex'
  import { formatRelative } from 'date-fns'
  import SharePost from '@/components/SharePost'
  import ShareProduct from '@/components/ShareProduct'

  export default {

    name: 'ConvoMessage',

    components: {
      SharePost,
      ShareProduct
    },

    props: {
      message: Object,
      fullwidth: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      componentLoaded: false,
      sharePost: null,
      shareProduct: null
    }),

    computed: {
      isYou() {
        return this.message.sender == this.user.account
      },
      displayDate() {
        return formatRelative(new Date(this.message.sent), new Date())
      },
      preparedText() {
        return this.message.text.replace(/\n/g, '<br/>')
      },
      showMeta() { return Boolean(this.sharePost || this.shareProduct) }
    },

    mounted() {
      let loader = null

      if (this.message.post) {
        loader = this.loadSharePost()
      } else if (this.message.product) {
        loader = this.loadShareProduct()
      }

      if (loader) {
        loader.then(() => this.componentLoaded = true)
      } else {
        this.componentLoaded = true
      }
    },

    methods: {
      ...mapActions([
        'getPost',
        'getProduct'
      ]),
      loadSharePost() {
        return this.getPost(this.message.post)
          .then(post => this.sharePost = {id: post.id, ...post.data()})
      },
      loadShareProduct() {
        return this.getProduct(this.message.product)
          .then(product => this.shareProduct = product)
      },
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_colors.scss';

  .v-list-item.message {
    /* Explicit rather than short hand to avoid errors */
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 80px;

    min-height: 30px;

    max-width: 100%;
    flex-wrap: wrap;
    position: relative;

    &.fullwidth {
      padding-left: 0;
      padding-right: 0;
    }

    &.is-you {
      padding-left: 80px;
      padding-right: 16px;

      &.fullwidth {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .v-card .v-card__text {
      padding: 0 8px;

      p {
        margin: 0;
        padding: 4px 0;
        overflow-wrap: anywhere;
      }

      a {
        color: inherit;
      }
    }

    .date {
      color: map-get($grey, "base");
      position: absolute;
      right: 16px;
      bottom: 0;
    }
  }
</style>
