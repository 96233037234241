<template>
  <v-select
    :items="items"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
  </v-select>
</template>

<script>
  export default {
    props: [
      'value',
      'customItems'
    ],
    computed: {
      items() {
        return this.customItems ? [ ...this.customItems, ...this.territories ] : this.territories
      },
      territories() {
        return Object.entries(this.$store.getters.territories)
          .map(([key, value]) => ({ value: key, text: value }))
      }
    }
  }
</script>