<template>
  <div>
    <v-select
      v-if="addressBook.length"
      :items="addressBook"
      :item-text="itemText"
      item-value="ulid"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
    </v-select>
    <p v-else>{{ $attrs.label }}: <span :class="{'red--text': $attrs['error-messages']}">You have not created any addresses yet!</span></p>
    <p v-if="help" class="caption">Add and update your stored addresses via <router-link :to="{ name: 'account-addressbook', params: { 'account': user.account }}">your address book</router-link></p>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      value: String,
      empty: String,
      help: Boolean
    },
    computed: {
      addressBook() {
        const addressBook = this.empty ? [{
          ulid: '',
          customText: this.empty
        }] : []

        return this.$store.getters.address ? addressBook.concat(this.$store.getters.address.book) : addressBook
      }
    },
    methods: {
      itemText(item) {
        return item.customText || `${item.description}: ${item.contact}, ${item.line1}, ${item.line2 ? item.line2 + ', ' : ''}${item.city ? item.city + ', ' : ''}${item.state ? item.state + ', ' : ''}${item.postalCode ? item.postalCode + ', ' : ''}${this.$store.getters.territory(item.country)}`
      }
    }
  }
</script>