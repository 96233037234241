<template>
  <v-navigation-drawer
    v-model="navigation"
    class="navigation"
    app
    fixed
    temporary
    right
  >
    <v-list nav dense subheader class="pt-2">
      <div v-if="user && user.account">
        <v-list-item :to="{name: 'profile', params: {account: user.account}}" exact>
          <v-list-item-avatar :color="accountMedia ? null : '#7070a0'" size="32" class="mr-4 mb-2">
            <v-img v-if="accountMedia" :src="accountMedia" :alt="user.account" />
            <span v-else class="white--text headline">{{ user.account[0].toUpperCase() }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.account }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>

      <v-list-item :to="{name: 'stream'}">
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$installer.canInstall && !$installer.hasInstalled"
        @click="$installer.prompt"
      >
        <v-list-item-title>Install Nuokka as an app</v-list-item-title>
      </v-list-item>

      <v-list-item href="https://nuokka.com/faq">
        <v-list-item-title>Help and FAQ</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="user" :to="{name: 'orders'}">
        <v-list-item-title>Order history</v-list-item-title>
      </v-list-item>

      <div v-if="user">
        <div v-if="isSeller">
          <v-divider></v-divider>
          <v-subheader class="">Merchant</v-subheader>
          <v-list-item :to="{name: 'account-orders', params: {'account': user.account}}">
            <v-list-item-title>Purchase orders</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'account-products-manage', params: {'account': user.account}}">
            <v-list-item-title>Your store</v-list-item-title>
          </v-list-item>
        </div>

        <v-divider></v-divider>
        <v-subheader>Settings</v-subheader>
        <v-list-item :to="{name: 'account-edit', params: {'account': user.account}}">
          <v-list-item-title>Account settings</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'user-edit'}">
          <v-list-item-title>User settings</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'account-addressbook', params: {'account': user.account}}">
          <v-list-item-title>Address book</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item @click="$store.dispatch('logout')">
          <v-list-item-title>Sign out</v-list-item-title>
        </v-list-item>
      </div>

      <div v-else>
        <v-list-item :to="{name: 'signup'}">
          <v-list-item-title>Sign up for Nuokka</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$store.dispatch('setShowLogin', true)">
          <v-list-item-title>Login to your account</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>

    <v-list class="legal" nav dense>
      <v-list-item :to="{name: 'legal-terms'}">
        <v-list-item-title class="caption">Terms</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'legal-privacy'}">
        <v-list-item-title class="caption">Privacy</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'legal-cookies'}">
        <v-list-item-title class="caption">Cookies</v-list-item-title>
      </v-list-item>
      <div class="text-center caption">
        v{{ version }}
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getImageURL } from '@/image-cdn'

  export default {

    name: 'Navigation',

    data: () => ({
      version: process.env.NUOKKA_VERSION
    }),

    computed: {
      ...mapGetters([
        'account',
        'isSeller'
      ]),
      accountMedia() {
        return this.account && this.account.media ?
          getImageURL(
            this.account.media,
            60, 60,
            this.account.updated || this.account.created
          ) :
          null
      },
      navigation: {
        get: function() {
          return this.$store.getters.navigation
        },
        set: function(newValue) {
          this.$store.dispatch('setNav', newValue)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navigation {
    z-index: 100;

    .v-subheader {
      height: 32px;
    }

    .v-list--dense.legal {
      position: fixed;
      bottom: 0;
      width: 100%;
      line-height: 1;
      text-align: center;

      .v-list-item {
        display: inline-block;
        min-height: auto;
        margin: 0 !important;

        .v-list-item__title {
          font-weight: normal;
        }
      }
    }
  }
</style>
