<template>
  <v-card-text>
    <v-alert
      class="caption stripe-notice"
      color="grey darken-1"
      outlined
    >
      <slot>
        We use Stripe to make sure you get paid on time and to keep
        your business payment details secure. As this is a merchant account,
        clicking <strong>Save and continue</strong> will redirect to Stripe
        so that you can either create a new Stripe account, or link an
        existing one to your Nuokka account.
      </slot>
    </v-alert>
  </v-card-text>
</template>

<script>
  export default {

    name: 'StripeNotice',

    data: () => ({})
  }
</script>

<style lang="scss" scoped>
  .v-alert.stripe-notice {
    background: url(/img/stripe-grey.svg) center 16px no-repeat !important;
    background-size: 80px auto !important;
    padding: 60px 16px 16px 16px;
  }
</style>
