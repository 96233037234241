<template>
  <v-alert
    :color="color"
    prominent
    dense
    outlined
    text
  >
    <v-row align="center">
      <v-col class="grow">
        <slot></slot>
      </v-col>
      <v-col v-if="action" class="shrink">
        <v-btn
          depressed
          :color="color"
          :dark="dark"
          @click="$emit('action-click')"
        >{{ action }}</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
  export default {

    name: 'Alert',

    props: [
      "color",
      "dark",
      "dense",
      "action"
    ],

    data: () => ({

    })
  }
</script>

<style lang="scss" scoped>
</style>
