<template>
  <v-list-item :to="link" link>
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">{{ formatDate(order.created) }}</v-list-item-title>
      <v-list-item-subtitle>{{ purchaseOrder ? order.customer.name : order.merchant.legalName }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ order.orderID }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-content class="justify-end text-right">
      <strong>{{ displayTotal(order) }}</strong>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  const zeroRated = [
    'bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga',
    'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf'
  ]

  export default {

    name: 'OrderSummary',

    props: {
      order: Object,
      purchaseOrder: Boolean
    },

    data() {
      return {

      }
    },

    computed: {
      link() {
        return this.purchaseOrder ? {
          name: 'account-order-detail',
          params: {
            account: this.user.account,
            order_id: this.order.orderID
          }
        } : {
          name: 'order-detail',
          params: {
            order_id: this.order.orderID
          }
        }
      }
    },

    methods: {
      currencyFactor: (currency) => currency in zeroRated ? 1 : 100,
      formatDate(date) {
        const options = {
          year: 'numeric', month: 'short', day: 'numeric',
          hour: 'numeric', minute: 'numeric',
          hour12: false,
        }
        return new Intl
          .DateTimeFormat('default', options)
          .format(new Date(date))
      },
      displayTotal(order) {
        return new Intl.NumberFormat(
          navigator.language || 'en', {
            style: 'currency',
            currency: order.payment.currency
          }).format(
            order.payment.amount /
            this.currencyFactor(order.payment.currency)
          )
      }
    }
  }
</script>

<style lang="scss">
</style>
